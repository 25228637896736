:root {
  --white:#fff;
  --black:#000;
  --blue:#00aed4;
  --light-blue:rgba(0,174,212,0.050980392156862744);
  --border-color:var(--light-blue);
  --yellow:#ff0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 20px 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

/* FONT & TEXT STYLING */
@font-face {
  font-family: "Telegraf";
  src: url("/theme/assets/fonts/Telegraf-UltraLight.woff2") format("woff2"), url("/theme/assets/fonts/Telegraf-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Telegraf";
  src: url("/theme/assets/fonts/Telegraf-Regular.woff2") format("woff2"), url("/theme/assets/fonts/Telegraf-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Telegraf";
  src: url("/theme/assets/fonts/Telegraf-Bold.woff2") format("woff2"), url("/theme/assets/fonts/Telegraf-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Telegraf";
  src: url("/theme/assets/fonts/Telegraf-UltraBold.woff2") format("woff2"), url("/theme/assets/fonts/Telegraf-UltraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Handel Gothic";
  src: url("/theme/assets/fonts/HandelGothic.woff2") format("woff2"), url("/theme/assets/fonts/HandelGothic.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
h1, h2, .h1-style {
  font-family: "Handel Gothic";
  margin-top: 0;
  text-transform: uppercase;
}
@media (max-width: 575.98px) {
  h1 br, h2 br, .h1-style br {
    display: none;
  }
}

h3, h4, h5, h6 {
  font-family: "Telegraf";
  margin-top: 0;
}
@media (max-width: 575.98px) {
  h3 br, h4 br, h5 br, h6 br {
    display: none;
  }
}

h1, .h1-style {
  font-size: 3.625rem;
  line-height: 0.97;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  h1, .h1-style {
    font-size: 3rem;
  }
}
@media (max-width: 575.98px) {
  h1 br, .h1-style br {
    display: none;
  }
}

@media (max-width: 767.98px) {
  h1, .h1-style {
    font-size: 1.8rem;
  }
}

h2, .footer-cta__title span {
  font-size: 2.375rem;
  line-height: 1;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  h2, .footer-cta__title span {
    font-size: 1.7rem;
  }
}

h3 {
  font-size: 2rem;
  line-height: 1.0625;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  h3 {
    font-size: 1.6rem;
  }
}

h4 {
  font-size: 1.625rem;
  line-height: 1.3;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  h4 {
    font-size: 1.4rem;
  }
}

h5 {
  font-size: 1.3125rem;
  line-height: 1;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  h5 {
    font-size: 1.2rem;
  }
}

h6 {
  font-size: 1.125rem;
  line-height: 1;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  h6 {
    font-size: 1rem;
  }
}

p, a, u, span, select, section, td, th, option, form, input, b, strong, ul, ol, li, textarea, label, button, input, iframe, table {
  font-family: "Telegraf";
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  p, a, u, span, select, section, td, th, option, form, input, b, strong, ul, ol, li, textarea, label, button, input, iframe, table {
    font-size: 0.9375rem;
  }
}

@font-face {
  font-family: "Social";
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("/theme/assets/fonts/social_media.woff2") format("woff2"), url("/theme/assets/fonts/social_media.woff") format("woff");
}
.icon-social {
  font-style: normal;
  font-family: "Social";
}
.icon-social.icon-facebook:before {
  content: "\f09a";
}
.icon-social.icon-twitter:before {
  content: "\f099";
}
.icon-social.icon-envelope:before {
  content: "\f0e0";
}
.icon-social.icon-linkedin:before {
  content: "\f0e1";
}
.icon-social.icon-youtube-play:before {
  content: "\f16a";
}
.icon-social.icon-instagram:before {
  content: "\f16d";
}
.icon-social.icon-google:before {
  content: "\f1a0";
}
.icon-social.icon-behance:before {
  content: "\f1b4";
}
.icon-social.icon-pinterest-p:before {
  content: "\f231";
}
.icon-social.icon-whatsapp:before {
  content: "\f232";
}
.icon-social.icon-vimeo:before {
  content: "\f27d";
}

/* DISPLAY FLEX STYLING */
.fl-container {
  display: flex;
}

.fl-row {
  flex-direction: row;
}

.fl-column {
  flex-direction: column;
}

.fl-wrap {
  flex-wrap: wrap;
}

.jc-start {
  justify-content: flex-start;
}

.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.ai-center {
  align-items: center;
}

/* IMAGE STYLING */
picture {
  position: relative;
  display: block;
  line-height: 0;
  height: 0;
}
picture img {
  position: absolute;
  top: 0;
}

.width-cover {
  width: 100%;
  height: 100%;
}

.width-cover img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.height-contain {
  width: 100%;
  height: 100%;
}

.height-contain img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.parallax-img {
  width: 100%;
  height: 100%;
}

.parallax-img img {
  width: 100%;
  height: 120%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: -10%;
}
@media (max-width: 767.98px) {
  .parallax-img img {
    height: 100%;
    margin-top: 0;
  }
}

.no-absolute {
  padding-bottom: 0 !important;
  height: auto;
}
.no-absolute img {
  position: relative !important;
}

.reveal-image {
  overflow: hidden;
}

.full-link-btn {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 2;
  top: 0;
  left: 0;
}

.plate--container {
  padding-left: 31px;
  padding-right: 31px;
  max-width: none;
}
@media (max-width: 767.98px) {
  .plate--container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.plate--page-content-wrapper {
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .plate--column {
    flex-basis: 100%;
    max-width: 100%;
    min-height: unset;
  }
}

.render-content {
  position: relative;
}

.section-container {
  position: relative;
}
.section-container.fullwidth {
  overflow: hidden;
}
.section-container.fullwidth .plate--container {
  padding-left: 0;
  padding-right: 0;
}
.section-container.indent .plate--container {
  padding-left: 71px;
  padding-right: 71px;
}
@media (max-width: 767.98px) {
  .section-container.indent .plate--container {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.section-container.has-bg::before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: inherit;
  position: absolute;
  top: -1px;
  z-index: 0;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-multirow-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

/* 3D Effects */
.swiper-container-3d {
  perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

/* CSS Mode */
.swiper-container-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}
.swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}
.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}

.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}

.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}

.swiper-container-cube {
  overflow: visible;
}
.swiper-container-cube .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  backface-visibility: hidden;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-flip {
  overflow: visible;
}
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-flip .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  backface-visibility: hidden;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s infinite linear;
  box-sizing: border-box;
  border: 4px solid #007aff;
  border-radius: 50%;
  border-top-color: transparent;
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 44px;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "prev";
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "next";
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #fff;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  transition: 200ms transform, 200ms top;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms left;
}
.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}

/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}
.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progressbar, .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-container-vertical > .swiper-pagination-progressbar, .swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-blue {
  --swiper-pagination-color: #0d6efd;
}

.swiper-pagination-indigo {
  --swiper-pagination-color: #6610f2;
}

.swiper-pagination-purple {
  --swiper-pagination-color: #6f42c1;
}

.swiper-pagination-pink {
  --swiper-pagination-color: #d63384;
}

.swiper-pagination-red {
  --swiper-pagination-color: #dc3545;
}

.swiper-pagination-orange {
  --swiper-pagination-color: #fd7e14;
}

.swiper-pagination-yellow {
  --swiper-pagination-color: #ffc107;
}

.swiper-pagination-green {
  --swiper-pagination-color: #198754;
}

.swiper-pagination-teal {
  --swiper-pagination-color: #20c997;
}

.swiper-pagination-cyan {
  --swiper-pagination-color: #0dcaf0;
}

.swiper-pagination-white {
  --swiper-pagination-color: #fff;
}

.swiper-pagination-gray {
  --swiper-pagination-color: #6c757d;
}

.swiper-pagination-gray-dark {
  --swiper-pagination-color: #343a40;
}

.swiper-pagination-lock {
  display: none;
}

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.swiper-zoom-container > img, .swiper-zoom-container > svg, .swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--black);
}
body.lock-scroll {
  overflow: hidden;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 102;
  background-color: var(--white);
  transition: transform 0.5s ease, background-color 0.5s ease;
}
.navigation .navbar {
  height: 100%;
}
.navigation .navbar-inner {
  height: inherit;
  width: 100%;
}
.navigation .navbar-nav {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
.navigation .nav-button {
  height: auto;
  margin-right: 0;
  margin-left: 25px;
  font-size: 0;
}
.navigation .navbar-collapse {
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .navigation .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: unset;
    overflow-y: auto;
    background-color: var(--blue);
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transition: opacity 0.4s ease, visibility 0.4s ease;
  }
  .navigation .navbar-collapse::-webkit-scrollbar {
    display: none;
  }
  .navigation .navbar-collapse__inner {
    overflow-y: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    max-height: 100vh;
    width: 100%;
  }
  .navigation .navbar-collapse__inner .navbar-nav {
    flex-direction: column;
    align-items: flex-start;
  }
  .navigation .navbar-collapse__inner::-webkit-scrollbar {
    display: none;
  }
}
.navigation.nav-down {
  transform: translateY(-100%);
}
.navigation.nav-up {
  transform: translateY(-10px);
}
.navigation.nav-top {
  transform: translateY(0) !important;
}
.navigation .navbar-toggler {
  display: none;
  z-index: 100;
  border: 0;
  background-color: transparent;
  width: auto;
  height: 32px;
  cursor: pointer;
  background: transparent;
  border-radius: 0;
  overflow: hidden;
  margin-left: auto;
  padding: 8px;
}
@media (max-width: 767.98px) {
  .navigation .navbar-toggler {
    display: block;
  }
}
.navigation .navbar-toggler .bars {
  height: auto;
  width: 100%;
  z-index: -1;
}
.navigation .navbar-toggler .bars .bar {
  width: 32px;
  height: 2px;
  background-color: var(--blue);
  margin-bottom: 5px;
  border-radius: 2px;
  transition: all 0.25s linear;
}
.navigation .navbar-toggler .bars .bar.bar-3 {
  margin-bottom: 0;
}
.navigation .navbar-toggler:focus {
  outline: none;
}
.navigation .navbar-brand {
  position: relative;
  margin-right: auto;
  z-index: 99;
}
.navigation .navbar-brand a {
  font-size: 0;
}
.navigation .navbar-brand .logo {
  transition: opacity 0.4s ease;
}
.navigation.dark .nav-item:not(.nav-button) .nav-link {
  color: var(--black);
}
.navigation.dark .navbar-brand .logo {
  opacity: 0;
}
.navigation.dark .navbar-brand .logo.-dark {
  opacity: 1;
}
.navigation.dark .navbar-toggler .bars .bar {
  background-color: var(--black);
}
.navigation.light-all .navbar-brand .logo {
  filter: brightness(0) invert(1);
}
.navigation.light-all .nav-button {
  background-color: var(--white);
}
.navigation.light-all .nav-button a {
  color: var(--orange);
}
.navigation.light-all .job-count {
  background-color: var(--white);
}
.navigation.light-all .job-count span {
  color: var(--orange);
}
.navigation.fixed-header {
  background-color: var(--white);
}
.navigation.fixed-header .navbar-brand .logo {
  filter: none;
  opacity: 0;
}
.navigation.fixed-header .navbar-brand .logo.-dark {
  opacity: 1;
}
.navigation.fixed-header .nav-item:not(.nav-button) .nav-link {
  color: var(--black);
}
.navigation.fixed-header .nav-item:not(.nav-button).active .nav-link {
  opacity: 1;
}
.navigation.fixed-header .nav-button {
  background-color: var(--orange);
}
.navigation.fixed-header .nav-button a {
  color: var(--white);
}
.navigation.fixed-header .job-count {
  background-color: var(--orange);
}
.navigation.fixed-header .job-count span {
  color: var(--white);
}
@-webkit-keyframes logoWhite {
  0% {
    opacity: 0;
  }
  50% {
    filter: brightness(0) invert(1);
  }
  to {
    opacity: 1;
    filter: brightness(0) invert(1);
  }
}
@keyframes logoWhite {
  0% {
    opacity: 0;
  }
  50% {
    filter: brightness(0) invert(1);
  }
  to {
    opacity: 1;
    filter: brightness(0) invert(1);
  }
}
.navigation.menu-open .navbar-collapse {
  visibility: visible;
  opacity: 1;
}
.navigation.menu-open .navbar-brand .logo {
  -webkit-animation: logoWhite;
  animation: logoWhite;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.navigation.menu-open .navbar-toggler .bars .bar {
  background-color: var(--white);
  transition: all 0.25s linear, background-color 0.25s linear;
}
.navigation.menu-open .navbar-toggler .bars .bar-1 {
  transform: rotate(45deg);
}
.navigation.menu-open .navbar-toggler .bars .bar-2 {
  opacity: 0;
}
.navigation.menu-open .navbar-toggler .bars .bar-3 {
  transform: rotate(-45deg);
  margin-top: -14px;
}

.select-arrow {
  pointer-events: none;
}

.nav-item {
  position: relative;
  margin: 0 5px;
}
@media (max-width: 767.98px) {
  .nav-item {
    overflow: hidden;
  }
}
.nav-item .nav-link {
  position: relative;
  color: var(--black);
  font-size: 1.0625rem;
  line-height: 1;
  font-weight: 400;
  text-decoration: none;
  transition: opacity 0.5s ease;
  padding: 4px 10px 2px;
  display: block;
}
@media (max-width: 767.98px) {
  .nav-item .nav-link {
    font-size: 8.5vw;
    line-height: 1.6;
    text-align: center;
    display: inline-block;
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 0;
    color: var(--white) !important;
  }
  .nav-item .nav-link .job-count {
    background-color: var(--white);
    top: -30px;
  }
  .nav-item .nav-link .job-count span {
    color: var(--orange);
  }
}
.nav-item.dropdown {
  overflow: visible;
}
.nav-item.dropdown .dropdown-arrow {
  position: relative;
  display: none;
  transform: rotate(90deg);
  top: -3px;
  margin-left: 8px;
  cursor: pointer;
}
.nav-item.dropdown .dropdown-arrow.active {
  transform: rotate(-90deg);
  transition: transform 0.6s cubic-bezier(0.905, 0.025, 0.145, 1);
}
.nav-item.dropdown .dropdown-menu {
  position: absolute;
  width: 200px;
  text-align: left;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  padding: 8px 20px;
  transition: visibility 0.5s ease, opacity 0.5s ease;
}
.nav-item.dropdown .dropdown-menu .dropdown-item {
  color: var(--white-transparent-5);
  line-height: 1.125rem;
  text-decoration: none;
  margin-bottom: 12px;
  transition: color 0.5s ease, transform 0.8s cubic-bezier(0.905, 0.025, 0.145, 1), opacity 0.8s cubic-bezier(0.905, 0.025, 0.145, 1);
  transform: translateY(20px);
  opacity: 0;
}
.nav-item.dropdown .dropdown-menu .dropdown-item:hover {
  color: var(--white);
}
.nav-item:last-child {
  margin-right: 0;
}
.nav-item:last-child .nav-link {
  padding-right: 0;
}

.header-search {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100px;
}
.header-search__inner {
  position: relative;
  width: calc(100% - 40px);
  height: calc(100vh - 120px);
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  overflow: hidden;
  z-index: 1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767.98px) {
  .header-search__inner {
    height: calc(70vh - 120px);
    padding-left: 20px;
    padding-right: 20px;
  }
}
.header-search__inner:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--black);
  opacity: 0.12;
  z-index: 1 !important;
}
.header-search__content {
  position: relative;
  height: 100%;
  width: 74%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}
@media (max-width: 767.98px) {
  .header-search__content {
    width: 100%;
    max-width: unset;
  }
}
.header-search__title h1 {
  color: #fff;
  margin: 0;
  text-transform: uppercase;
}
.header-search__title h1 span {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: inherit;
  color: var(--blue);
}
.header-search__subtitle {
  margin-top: 10px;
  margin-bottom: 36px;
}
@media (max-width: 575.98px) {
  .header-search__subtitle {
    margin-bottom: 20px;
  }
}
.header-search__subtitle span {
  font-size: 1.5rem;
  line-height: 0.9;
  color: #fff;
}
@media (max-width: 575.98px) {
  .header-search__subtitle span {
    font-size: 0.9375rem;
  }
}
@media (max-width: 767.98px) {
  .header-search__bottom {
    flex-direction: column;
  }
}
.header-search__bottom, .header-search__usps {
  display: flex;
  flex-direction: row;
}
.header-search__usps {
  position: relative;
  width: 100%;
  background-color: var(--black);
  padding-top: 148px;
  padding-bottom: 80px;
  margin-top: -62px;
}
@media (max-width: 767.98px) {
  .header-search__usps {
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  .header-search__usps .cta {
    width: 100%;
    margin-bottom: 16px;
  }
}
.header-search__usps .cta:nth-child(3) {
  margin-right: 0;
}

.title-color span {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-transform: inherit;
  color: var(--blue);
}

.cta {
  position: relative;
  padding: 40px;
  margin-right: 16px;
  border-radius: 30px;
  width: calc(33.33333% - 10.66667px);
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .cta {
    padding-left: 30px;
    padding-bottom: 36px;
    padding-right: 30px;
    padding-top: 36px;
  }
}
.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--blue);
  z-index: 0;
  opacity: 0;
  transition: opacity 0.4s ease;
}
.cta__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  z-index: 1;
}
.cta__title {
  margin-bottom: 20px;
}
.cta__title h3 {
  color: var(--white);
  margin-bottom: 0;
}
.cta__title h3 span {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: inherit;
  font-weight: inherit;
  color: var(--blue);
  transition: color 0.4s ease;
}
.cta__text {
  margin-bottom: 94px;
}
@media (max-width: 767.98px) {
  .cta__text {
    margin-bottom: 30px;
  }
}
.cta__text p {
  margin-top: 0;
  color: var(--white);
  transition: color 0.4s ease;
}
.cta__link {
  margin-top: auto;
}
.cta:hover:before {
  opacity: 1;
}
.cta:hover .cta__title h3 span {
  color: var(--black);
}
.cta:hover .cta__text p {
  color: var(--black);
}
.cta:hover .cta__link .text-link {
  color: var(--black) !important;
}
.cta:hover .cta__link .text-link svg path {
  fill: var(--white);
}

.text-link {
  display: inline-block;
  transition: color 0.4s ease;
}
.text-link svg {
  position: relative;
  top: -1px;
  margin-right: 15px;
}
.text-link svg path {
  transition: fill 0.4s ease;
}
.text-link a {
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.featured {
  position: relative;
}
.featured__title {
  margin-bottom: 34px;
  padding-left: 40px;
}
.featured__title h2 {
  text-transform: uppercase;
  margin-bottom: 0;
}
.featured__items {
  position: relative;
}

.items-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .items-container .post-item:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 575.98px) {
  .items-container .post-item {
    margin-right: 0 !important;
  }
}
.items-container .post-item:nth-child(3n) {
  margin-right: 0;
}
@media (max-width: 767.98px) {
  .items-container .post-item:nth-child(3n) {
    margin-right: 16px;
  }
}
.items-container.__1n .post-item {
  width: 100%;
  margin-right: 0;
}
.items-container.fade-out .post-item {
  opacity: 0;
}

.post-item {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 16px;
  margin-right: 16px;
  width: calc(33.33333% - 10.66667px);
  transition: opacity 0.4s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
@media (max-width: 767.98px) {
  .post-item {
    width: calc(50% - 8px);
  }
}
@media (max-width: 575.98px) {
  .post-item {
    width: 100%;
  }
}
.post-item__inner {
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 767.98px) {
  .post-item__inner {
    padding-left: 30px;
    padding-bottom: 32px;
    padding-right: 30px;
    padding-top: 32px;
  }
}
.post-item__bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-top: auto;
}

.vacature-item {
  position: relative;
  border: 1px solid var(--border-color);
  background-color: var(--white);
  transition: background-color 0.4s ease, opacity 0.4s ease;
}
.vacature-item__logo {
  margin-bottom: 38px;
}
.vacature-item__logo img {
  max-height: 46px;
  max-width: 160px;
}
.vacature-item__info {
  margin-bottom: 28px;
}
.vacature-item__info h4 {
  margin-bottom: 1px;
}
.vacature-item__info p {
  margin-bottom: 0;
}
.vacature-item__info p a {
  color: var(--blue);
  text-decoration: underline;
}
.vacature-item__info .intro {
  max-width: 650px;
  margin-right: 30px;
  margin-top: 12px;
}
.vacature-item__bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}
.vacature-item__city {
  position: relative;
  margin-right: 16px;
}
.vacature-item__city p {
  margin-bottom: 0;
  padding-left: 28px;
}
.vacature-item__city svg {
  position: absolute;
  left: 0;
  top: -1px;
}
.vacature-item__button {
  flex-shrink: 0;
}
.vacature-item.--index .vacature-item__inner {
  display: flex;
  flex-direction: row;
}
@media (max-width: 575.98px) {
  .vacature-item.--index .vacature-item__inner {
    flex-direction: column;
  }
}
.vacature-item.--index .vacature-item__logo {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  max-width: 100px;
  margin-right: 30px;
}
@media (max-width: 575.98px) {
  .vacature-item.--index .vacature-item__logo {
    max-width: unset;
    margin-bottom: 38px;
    margin-right: 0;
    align-items: flex-start;
  }
}
.vacature-item.--index .vacature-item__logo img {
  width: 100%;
}
.vacature-item.--index .vacature-item__info {
  margin-bottom: 0;
  margin-right: 20px;
}
.vacature-item.--index .vacature-item__bottom {
  width: 410px;
  margin-left: auto;
}
@media (max-width: 575.98px) {
  .vacature-item.--index .vacature-item__bottom {
    width: 100%;
    margin-top: 20px;
  }
}
.vacature-item:hover {
  background-color: var(--light-blue);
}

@media (max-width: 1023.98px) {
  .people-item .vacature-item__inner {
    flex-direction: column !important;
  }
}
@media (max-width: 1023.98px) {
  .people-item .vacature-item__info .intro {
    margin-right: 0;
  }
}
.people-item .vacature-item__bottom {
  margin-top: auto;
}
@media (max-width: 1023.98px) {
  .people-item .vacature-item__bottom {
    width: 100% !important;
    margin-top: 20px;
  }
}

.werkgever-item {
  position: relative;
  background-color: var(--light-blue);
}
.werkgever-item__info {
  max-width: 90%;
}
.werkgever-item__info h3 {
  margin-bottom: 16px;
}
.werkgever-item__info p {
  margin-bottom: 100px;
}
@media (max-width: 767.98px) {
  .werkgever-item__info p {
    margin-bottom: 40px;
  }
}
.werkgever-item__bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.werkgever-item__button {
  flex-shrink: 0;
  font-size: 0;
}
.werkgever-item__logo {
  margin-left: 16px;
}
.werkgever-item__logo img {
  max-height: 46px;
  max-width: 160px;
  margin-left: auto;
}

.button-wrapper {
  font-size: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.button-wrapper .button:not(:last-child) {
  margin-right: 16px;
}

.button {
  --btn-transition:.4s ease;
  position: relative;
  height: 58px;
  display: inline-block;
  transition: all var(--btn-transition);
  border: 0;
  padding: 0;
  background-color: var(--blue);
  border-radius: 6px;
}
.button a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 30px 0;
  height: 100%;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  color: var(--white);
  width: inherit;
  transition: all var(--btn-transition);
}
.button:focus {
  outline: none;
}
.button.diapositive {
  background-color: var(--white);
}
.button.diapositive a {
  color: var(--orange);
}
.button.whitespace {
  margin-top: 20px;
}

.text-image {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 575.98px) {
  .text-image {
    flex-direction: column;
  }
}
.text-image__left, .text-image__right {
  position: relative;
}
@media (max-width: 575.98px) {
  .text-image__left, .text-image__right {
    width: 100% !important;
  }
}
.text-image__left {
  width: 48%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-right: 11%;
}
.text-image__left h2 {
  margin-bottom: 30px;
}
.text-image__left p {
  margin-bottom: 2rem;
}
@media (max-width: 575.98px) {
  .text-image__left {
    margin-bottom: 30px;
  }
}
.text-image__right {
  width: 52%;
}
.text-image__right img {
  border-radius: 30px;
}
.text-image__right.with-border picture {
  border: 1px solid var(--blue);
  border-radius: 30px;
}
.text-image__right.with-border picture img {
  top: 30px;
  left: 30px;
}

.usps {
  margin-bottom: 40px;
}
.usps p {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  transition: color 0.4s ease;
}
.usps p svg {
  position: relative;
  top: -1px;
  margin-right: 14px;
  flex-shrink: 0;
}
.usps p svg path {
  transition: fill 0.4s ease;
}

.logo-slider {
  position: relative;
}
.logo-slider .swiper-wrapper {
  transition-timing-function: linear;
}
.logo-slider__title {
  margin-bottom: 90px;
  padding-left: 40px;
}
.logo-slider__title h2 {
  color: var(--dark-blue);
}
.logo-slider__logo {
  position: relative;
  margin-left: 60px;
  margin-right: 60px;
  width: auto;
}
@media (max-width: 575.98px) {
  .logo-slider__logo {
    margin-left: 40px;
    margin-right: 40px;
  }
}
.logo-slider__logo img {
  height: 55px;
  max-width: 175px;
  pointer-events: none;
}
@media (max-width: 575.98px) {
  .logo-slider__logo img {
    height: 40px;
    max-width: 120px;
  }
}

.footer-cta {
  position: relative;
  padding-left: 100px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767.98px) {
  .footer-cta {
    padding-left: 0;
  }
}
.footer-cta:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--black);
  opacity: 0.7;
}
.footer-cta__inner {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 110px;
  padding-bottom: 110px;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .footer-cta__inner {
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.footer-cta__left, .footer-cta__right {
  width: 50%;
}
@media (max-width: 767.98px) {
  .footer-cta__left, .footer-cta__right {
    width: 100%;
  }
}
.footer-cta__title {
  margin-bottom: 26px;
}
.footer-cta__title span {
  color: var(--white);
  font-size: 2.375rem;
}
@media (max-width: 767.98px) {
  .footer-cta__title span {
    font-size: 1.7rem;
  }
}
.footer-cta__links {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .footer-cta__links {
    margin-bottom: 30px;
    width: 100%;
  }
}
.footer-cta__links .text-link {
  min-width: 50%;
  margin-bottom: 20px;
}
.footer-cta__links .text-link a {
  color: var(--white);
}
.footer-cta__links.region .text-link a {
  text-transform: capitalize;
}

.footer {
  position: relative;
  background-color: var(--blue);
}
.footer__inner {
  padding-top: 92px;
  padding-bottom: 28px;
}
@media (max-width: 767.98px) {
  .footer__inner {
    padding-top: 66px;
    padding-bottom: 46px;
  }
}
.footer__top {
  display: flex;
  flex-direction: row;
}
@media (max-width: 767.98px) {
  .footer__top {
    flex-direction: column;
  }
}
.footer__top__logo {
  width: 100px;
  margin-bottom: 70px;
  position: relative;
  padding-bottom: 220px;
}
@media (max-width: 767.98px) {
  .footer__top__logo {
    width: auto;
    margin-bottom: 40px;
    padding-bottom: 0;
  }
}
.footer__top__logo img {
  position: absolute;
  left: -95px;
  top: 96px;
  transform: rotate(-90deg);
}
@media (max-width: 767.98px) {
  .footer__top__logo img {
    position: relative;
    left: unset;
    top: unset;
    transform: none;
  }
}
.footer__top__left {
  width: calc(50% - 50px);
}
@media (max-width: 767.98px) {
  .footer__top__left {
    margin-bottom: 46px;
    width: 100%;
  }
}
.footer__top__left p {
  margin-bottom: 0;
}
.footer__top__left p .space-before {
  width: 150px;
  display: inline-block;
}
@media (max-width: 767.98px) {
  .footer__top__left p .space-before {
    width: 120px;
  }
}
.footer__top__left p a {
  color: inherit;
  text-decoration: none;
}
.footer__top__right {
  width: calc(50% - 50px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .footer__top__right {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .footer__top__right {
    flex-direction: column;
  }
}
.footer__top__right .social-media {
  width: 100%;
}
.footer__top__right .social-media .social-media__icon:first-child {
  margin-left: 0;
}
.footer__column {
  flex: 1;
}
@media (max-width: 767.98px) {
  .footer__column {
    margin-bottom: 45px;
  }
}
.footer__column__links .link {
  position: relative;
}
.footer__column__links .link a {
  text-decoration: none;
}
.footer__bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
@media (max-width: 767.98px) {
  .footer__bottom {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
.footer__bottom__left, .footer__bottom__right {
  width: auto;
}
.footer__bottom__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767.98px) {
  .footer__bottom__right {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}
.footer__bottom .footer__copyright p {
  font-size: 0.8125rem;
  margin-bottom: 0;
}
.footer__bottom .footer__links {
  display: flex;
  flex-direction: row;
}
@media (max-width: 767.98px) {
  .footer__bottom .footer__links {
    margin-bottom: 20px;
  }
}
.footer__bottom .footer__links .link {
  margin-left: 50px;
}
@media (max-width: 767.98px) {
  .footer__bottom .footer__links .link {
    margin-left: 0;
    margin-right: 15px;
  }
}
.footer__bottom .footer__links .link a {
  font-size: 0.8125rem;
}
.footer__title {
  margin-bottom: 31px;
}
.footer .link a {
  color: var(--black);
}

.social-media {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.social-media__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: 52px;
  background-color: var(--black);
  margin-left: 10px;
  border-radius: 50%;
  flex-shrink: 0;
}
.social-media__icon svg {
  height: 18px;
  width: 18px;
}

.social-share__text {
  margin-right: 20px;
}
.social-share__text p {
  margin-bottom: 0;
}
.social-share__copy svg {
  width: 24px;
  height: 24px;
}

.post-header__inner {
  height: auto;
  padding-top: 124px;
  padding-bottom: 90px;
}
@media (max-width: 767.98px) {
  .post-header__inner {
    padding-left: 20px;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-top: 86px;
  }
}
.post-header__inner.with-blur:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}
.post-header__inner.form-active .post-header__titles {
  padding-right: 12%;
  width: 50%;
}
@media (max-width: 575.98px) {
  .post-header__inner.form-active .post-header__titles {
    width: 100%;
  }
}
.post-header__inner.form-active .post-header__content {
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 575.98px) {
  .post-header__inner.form-active .post-header__content {
    flex-direction: column;
  }
}
.post-header__inner.--row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 767.98px) {
  .post-header__inner.--row {
    flex-direction: column;
    align-items: flex-start;
  }
}
.post-header__inner.--row .post-header__content {
  margin-left: 15px;
}
@media (max-width: 767.98px) {
  .post-header__inner.--row .post-header__content {
    margin-left: 0;
  }
}
@media (max-width: 767.98px) {
  .post-header__inner.--row .post-header__circle {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
  }
}
.post-header__inner.--row .post-header__circle__inner {
  position: relative;
  top: -20px;
  width: 56%;
  background-color: var(--white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 8%;
}
@media (max-width: 767.98px) {
  .post-header__inner.--row .post-header__circle__inner {
    width: 100%;
    top: 0;
  }
}
.post-header__inner.--row .post-header__circle__inner:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.post-header__inner.--row .post-header__circle__inner picture {
  width: calc(100% - 40px);
}
.post-header__inner.--row .post-header__circle.people-circle .post-header__circle__inner {
  overflow: hidden;
  background-color: transparent;
}
.post-header__inner.--row .post-header__circle.people-circle picture {
  width: 100%;
}
.post-header__inner.--row .post-header__circle.people-circle picture img {
  -o-object-fit: cover;
  object-fit: cover;
}
.post-header__form {
  width: 50%;
}
@media (max-width: 575.98px) {
  .post-header__form {
    width: 100%;
  }
}
.post-header__form .contact-form .avg_checkbox {
  margin-left: 0;
}
.post-header__form .contact-form .avg_checkbox .option p {
  color: var(--white);
}
.post-header__form .contact-form .form-field__label {
  display: none;
}
.post-header.no-image .post-header__inner {
  background-color: var(--blue);
}
.post-header.no-image .post-header__inner:before {
  display: none;
}
.post-header.no-image .post-header__title span {
  color: var(--white);
}

.title-row {
  width: 100%;
  margin-bottom: 30px;
}
.title-row h2 {
  margin-bottom: 0;
}

.index-container {
  padding-top: 64px;
  padding-bottom: 80px;
}
.index-container__title {
  margin-bottom: 34px;
  padding-left: 40px;
}
@media (max-width: 767.98px) {
  .index-container__title {
    padding-left: 0;
  }
}
.index-container__title h2 {
  text-transform: uppercase;
  margin-bottom: 0;
}
.index-container__sidebar {
  position: relative;
  width: calc(24% - 8px);
}
@media (max-width: 1199.98px) {
  .index-container__sidebar {
    width: calc(30% - 8px);
  }
}
@media (max-width: 767.98px) {
  .index-container__sidebar {
    width: 100%;
    margin-bottom: 32px;
  }
}
.index-container__sidebar h5 {
  margin-bottom: 15px;
}
@media (max-width: 767.98px) {
  .index-container__sidebar .sidebar {
    padding-left: 30px;
    padding-bottom: 22px;
    padding-right: 30px;
    padding-top: 36px;
  }
}
.index-container__content {
  position: relative;
  width: calc(76% - 8px);
}
@media (max-width: 1199.98px) {
  .index-container__content {
    width: calc(70% - 8px);
  }
}
@media (max-width: 767.98px) {
  .index-container__content {
    width: 100%;
  }
}
.index-container__content.more-space {
  width: calc(70% - 8px);
  padding-right: 6%;
}
@media (max-width: 767.98px) {
  .index-container__content.more-space {
    width: 100%;
  }
}
@media (max-width: 1023.98px) {
  .index-container.vacature-index .index-container__sidebar {
    width: 100%;
    margin-bottom: 32px;
  }
}
@media (max-width: 1023.98px) {
  .index-container.vacature-index .index-container__sidebar .filter-title h5 svg {
    display: block;
  }
}
@media (max-width: 1023.98px) {
  .index-container.vacature-index .index-container__sidebar .filter-title h5 {
    margin-bottom: 14px;
    cursor: pointer;
  }
}
@media (max-width: 1023.98px) {
  .index-container.vacature-index .index-container__sidebar .sidebar .taxonomy__checkbox__wrapper {
    margin-bottom: 14px;
  }
  .index-container.vacature-index .index-container__sidebar .sidebar .taxonomy__checkbox__wrapper .form-field {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
}
@media (max-width: 1023.98px) {
  .index-container.vacature-index .index-container__content {
    width: 100%;
  }
}

.show-container {
  padding-top: 100px;
  padding-bottom: 60px;
}
.show-container.--bottom {
  padding-top: 60px;
  padding-bottom: 100px;
}
.show-container__bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 575.98px) {
  .show-container__bottom {
    flex-direction: column !important;
    align-items: flex-start;
  }
  .show-container__bottom .social-media {
    margin-bottom: 30px;
  }
}
.show-container__buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.show-container__buttons .button {
  margin-right: 16px;
}
.show-container__info {
  width: 100%;
}
.show-container__info .info-item {
  margin-bottom: 60px;
}
.show-container__info .info-item h3 {
  margin-bottom: 28px;
}
.show-container__info .info-item__list.region span {
  text-transform: capitalize;
}
.show-container__intro .intro-tekst p {
  margin-bottom: 0;
}
.show-container .back-to-index a {
  color: var(--black);
  text-decoration: none;
}
.show-container .back-to-index a svg {
  transform: rotate(180deg);
  margin-right: 6px;
}

.row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sidebar {
  position: relative;
  padding: 34px 40px;
  background-color: var(--light-blue);
  border-radius: 30px;
}

.search-form {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}
@media (max-width: 575.98px) {
  .search-form {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.search-form__city, .search-form__radius, .search-form__text {
  position: relative !important;
  width: 100%;
}
@media (max-width: 575.98px) {
  .search-form__city, .search-form__radius, .search-form__text {
    width: 49%;
    height: 40px;
    margin-bottom: 2%;
  }
}
.search-form__city:after, .search-form__radius:after, .search-form__text:after {
  content: "";
  height: 60%;
  width: 1px;
  position: absolute;
  right: 0;
  background-color: #deebed;
  top: 20%;
}
.search-form__text input {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.search-form__city input::-webkit-calendar-picker-indicator {
  display: none !important;
}
.search-form__radius select {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
@media (max-width: 575.98px) {
  .search-form__radius select {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
.search-form__radius .select-arrow {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
.search-form__radius .select-arrow path {
  fill: var(--black);
}
.search-form__radius:after {
  display: none;
}
.search-form__submit {
  flex-shrink: 0;
  margin-left: 10px;
}
@media (max-width: 575.98px) {
  .search-form__submit {
    width: 49%;
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  .search-form__submit .button {
    height: 40px;
    width: 100%;
  }
}
.search-form__submit .button a {
  color: var(--white) !important;
}
.search-form input, .search-form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  height: 100%;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  background-color: var(--white);
}
@media (max-width: 575.98px) {
  .search-form input, .search-form select {
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 6px;
  }
}
.search-form input:focus, .search-form select:focus {
  outline: 0;
}
.search-form input::-moz-placeholder, .search-form select::-moz-placeholder {
  color: var(--black);
}
.search-form input:-ms-input-placeholder, .search-form select:-ms-input-placeholder {
  color: var(--black);
}
.search-form input::placeholder, .search-form select::placeholder {
  color: var(--black);
}

.posts-filter .filter-title h5 {
  margin-bottom: 20px;
}
.posts-filter .filter-title h5 svg {
  display: none;
  float: right;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}
@media (max-width: 767.98px) {
  .posts-filter .filter-title h5 svg {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .posts-filter .filter-title h5 {
    margin-bottom: 14px;
    cursor: pointer;
  }
}
.posts-filter .filter-title.active h5 svg {
  transform: rotate(-90deg);
}
.posts-filter .taxonomy__checkbox__wrapper {
  position: relative;
  margin-bottom: 30px;
}
@media (max-width: 767.98px) {
  .posts-filter .taxonomy__checkbox__wrapper {
    margin-bottom: 14px;
  }
  .posts-filter .taxonomy__checkbox__wrapper .form-field {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
}
.posts-filter .taxonomy__checkbox__wrapper:last-child {
  margin-bottom: 0 !important;
}
.posts-filter .taxonomy__checkbox__wrapper .option {
  width: 100% !important;
}
.posts-filter .taxonomy__checkbox__wrapper .option label {
  width: 100%;
  display: flex;
  cursor: pointer;
}
.posts-filter .taxonomy__checkbox__wrapper .option label span {
  margin-left: auto;
}
.posts-filter .taxonomy__checkbox__wrapper input[type=checkbox] {
  width: 0;
  height: 0;
  visibility: hidden;
}
.posts-filter .filter-input-regio {
  text-transform: capitalize;
}
.posts-filter .filter-input {
  transition: color 0.4s ease;
}
.posts-filter .filter-input.active {
  color: var(--blue);
}

.intro-tekst, .titel-element {
  position: relative;
}

.intro-tekst p {
  font-size: 1.25rem;
  line-height: 1.1;
  color: var(--blue);
}
@media (max-width: 767.98px) {
  .intro-tekst p {
    font-size: 1.0625rem;
  }
}

.faq-item {
  position: relative;
  margin-bottom: 60px;
}
.faq-item__anchor {
  display: block;
  height: 25vh;
  margin-top: -25vh;
  visibility: hidden;
  position: absolute;
}
.faq-item__text, .faq-item h3 {
  margin-bottom: 26px;
}
.faq-item__text p {
  margin-bottom: 0;
}
.faq-item__read-more a {
  font-size: 1.0625rem;
  color: var(--blue);
  text-decoration: none;
}
.faq-item__read-more a svg {
  vertical-align: text-top;
  margin-right: 14px;
}

.featured-packages .featured__title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 40px;
}
@media (max-width: 575.98px) {
  .featured-packages .featured__title h2 {
    margin-bottom: 20px;
  }
}
@media (max-width: 575.98px) {
  .featured-packages .featured__title {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.featured-packages .featured__title .featured__text {
  width: calc(33.33333% - 20px);
}
@media (max-width: 575.98px) {
  .featured-packages .featured__title .featured__text {
    width: 100%;
    margin-bottom: 0;
  }
}
.featured-packages .pakket-item {
  width: calc(25% - 10.5px);
  margin-right: 14px;
  margin-bottom: 14px;
}
@media (max-width: 1023.98px) {
  .featured-packages .pakket-item {
    width: calc(50% - 7px);
  }
}
@media (max-width: 575.98px) {
  .featured-packages .pakket-item {
    width: 100%;
    margin-right: 0 !important;
  }
}
.featured-packages .pakket-item:nth-child(4n) {
  margin-right: 0;
}
@media (max-width: 1023.98px) {
  .featured-packages .pakket-item:nth-child(2n) {
    margin-right: 0;
  }
}

.pakket-item {
  position: relative;
  border: 1px solid var(--border-color);
  padding: 30px 32px;
  border-radius: 30px;
  background-color: var(--white);
  transition: background-color 0.4s ease;
}
.pakket-item__top {
  position: relative;
}
.pakket-item__top .usps {
  margin-bottom: 60px;
}
.pakket-item__text {
  padding-bottom: 28px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 34px;
}
.pakket-item__text h3 {
  margin-bottom: 12px;
  transition: color 0.4s ease;
}
.pakket-item__text h3 span {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: var(--blue);
}
.pakket-item__text p {
  line-height: 1.375;
  margin-bottom: 0;
  transition: color 0.4s ease;
}
.pakket-item__price {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.pakket-item__price span {
  transition: color 0.4s ease;
}
.pakket-item__price .price {
  font-size: 3.125rem;
  line-height: 1;
  margin-right: 16px;
}
.pakket-item__price .price--text {
  align-self: flex-end;
  padding-bottom: 4px;
}
.pakket-item__button .button {
  width: 100%;
}
.pakket-item:hover {
  background-color: var(--blue);
}
.pakket-item:hover p, .pakket-item:hover span {
  color: var(--white);
}
.pakket-item:hover svg path {
  fill: var(--white);
}
.pakket-item:hover h3 {
  color: var(--white);
}
.pakket-item:hover h3 span {
  color: inherit;
}
.pakket-item:hover .button {
  background-color: var(--white);
}
.pakket-item:hover .button a {
  color: var(--blue);
}

.anchors {
  position: relative;
}
.anchors .anchor {
  display: block;
  line-height: 2;
  text-decoration: none;
  color: var(--black);
  transition: color 0.4s ease;
}
.anchors .anchor:hover {
  color: var(--blue);
}

.summary {
  position: relative;
  padding: 45px;
  border-radius: 30px;
  background-color: var(--blue);
  margin-bottom: 30px;
}
@media (max-width: 767.98px) {
  .summary {
    padding-left: 30px;
    padding-bottom: 36px;
    padding-right: 30px;
    padding-top: 36px;
  }
}
.summary h3 {
  color: var(--black);
  margin-bottom: 30px;
}
.summary p {
  margin-bottom: 16px;
}
.summary.light {
  background-color: var(--light-blue);
}
.summary .usps {
  margin-bottom: 0;
}
.summary.dark .usps svg path {
  fill: var(--white);
}

.form-font-size {
  font-size: 0;
}

.form-errors ul {
  padding-left: 17px;
}

.form-field-container {
  position: relative;
  margin-bottom: 15px;
  background-color: transparent;
}
.form-field-container .form-field {
  display: block;
  position: relative;
  z-index: 3;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 575.98px) {
  .form-field-container .form-field {
    flex-direction: column;
    align-items: flex-start;
  }
}
.form-field-container .form-field__label {
  position: relative;
  z-index: 0;
  transition: all 0.4s ease;
  color: var(--black);
  line-height: normal;
  width: 220px;
  flex-shrink: 0;
}
@media (max-width: 575.98px) {
  .form-field-container .form-field__label {
    display: none;
  }
}
.form-field-container .form-field__label .required {
  color: inherit;
}
.form-field-container .form-field__label, .form-field-container .form-field__label__file {
  font-size: 1rem;
  margin-bottom: 0;
}
.form-field-container .form-field__label__file {
  height: 48px;
  padding: 12px 17px 15px;
  background-color: var(--blue);
  color: var(--white);
  margin-right: 26px;
  cursor: pointer;
}
.form-field-container .form-field__label__file svg {
  height: 12px;
  margin-left: 8px;
  transform: rotate(90deg);
}
.form-field-container .form-field input[type=file]::file-selector-button {
  display: none;
}
@media (max-width: 575.98px) {
  .form-field-container .form-field.checkbox .form-field__label, .form-field-container .form-field.radio .form-field__label {
    margin-top: 15px;
    margin-bottom: 15px;
    display: block !important;
  }
}
.form-field-container .form-field.checkbox .option-group .option, .form-field-container .form-field.radio .option-group .option {
  margin-right: 30px;
  margin-bottom: 10px !important;
}
.form-field-container .form-field.checkbox .option-group .option:last-child, .form-field-container .form-field.radio .option-group .option:last-child {
  margin-bottom: 0 !important;
}
.form-field-container .form-field.checkbox .option-group .option label, .form-field-container .form-field.radio .option-group .option label {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.form-field-container .form-field.checkbox .option-group .option label input, .form-field-container .form-field.radio .option-group .option label input {
  position: relative !important;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 20px;
  flex-shrink: 0;
  background-color: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 3px;
}
.form-field-container .form-field.checkbox .option-group .option label input:before, .form-field-container .form-field.radio .option-group .option label input:before {
  content: "L";
  top: 0;
  left: 6px;
  color: var(--black-bg);
  border-radius: 0;
  position: absolute;
  opacity: 0;
  transition: opacity 0.4s ease;
  font-family: Telegraf;
  transform: perspective(1px) scaleX(-1) rotate(-44deg);
  font-weight: 400;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.form-field-container .form-field.checkbox .option-group .option label input:checked:before, .form-field-container .form-field.radio .option-group .option label input:checked:before {
  opacity: 1;
}
.form-field-container .form-field.radio .option-group .option label input {
  border-radius: 50% !important;
}
.form-field-container .form-field.radio .option-group .option label input:before {
  content: "";
  top: 4px;
  left: 4px;
  background-color: var(--blue);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  transition: opacity 0.4s ease;
  transform: none;
  font-weight: 400;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.form-field-container .form-field .select-arrow {
  top: 50%;
  right: 30px;
  position: absolute;
  z-index: 44;
  filter: brightness(0);
  transform: translateY(-50%) rotate(90deg);
}
.form-field-container.is-placed-after .button {
  float: right;
}
@media (max-width: 575.98px) {
  .form-field-container.is-placed-after .button {
    float: unset;
  }
}
.form-field-container.is-placed-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 575.98px) {
  .form-field-container.is-placed-inline {
    flex-direction: column;
    align-items: flex-start;
  }
}
.form-field-container.whole {
  width: 100%;
}
.form-field-container.half {
  width: calc(50% - 5px);
}
@media (max-width: 575.98px) {
  .form-field-container.half {
    width: 100%;
  }
}

.form-field__input, .form-field__select, .form-field__textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  display: block;
  outline: 0;
  width: 100%;
  z-index: 2;
  height: 60px;
  color: var(--black);
  padding: 20px 25px;
  background-color: var(--white);
  font-weight: 400;
}
.form-field__input::-moz-placeholder, .form-field__select::-moz-placeholder, .form-field__textarea::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.form-field__input:-ms-input-placeholder, .form-field__select:-ms-input-placeholder, .form-field__textarea:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.form-field__input::placeholder, .form-field__select::placeholder, .form-field__textarea::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.form-field__input.invalid, input[type=checkbox].invalid {
  border: 2px solid red !important;
}

.form-field__textarea {
  min-height: 130px;
}

.contact-form {
  position: relative;
}
.contact-form__fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact-form .submit-form-btn a {
  color: var(--white) !important;
}
.contact-form.diapositive .form-field-container .form-field label {
  color: var(--white);
}
.contact-form.diapositive .form-field-container .form-field label .required {
  color: var(--white) !important;
}
.contact-form.diapositive .form-field-container .form-field .form-field__input, .contact-form.diapositive .form-field-container .form-field .form-field__select, .contact-form.diapositive .form-field-container .form-field .form-field__textarea {
  color: var(--white);
  border-bottom: 2px solid var(--white);
}
.contact-form.diapositive .form-field-container .form-field.checkbox .option-group .option label input, .contact-form.diapositive .form-field-container .form-field.radio .option-group .option label input {
  border: 2px solid var(--white);
}
.contact-form.diapositive .form-field-container .form-field.checkbox .option-group .option label input:before, .contact-form.diapositive .form-field-container .form-field.radio .option-group .option label input:before {
  background-color: var(--white);
}
.contact-form.diapositive .avg_checkbox .option a {
  color: var(--white);
  text-decoration: underline;
}

.avg_checkbox {
  opacity: 1 !important;
  margin-bottom: 30px;
  margin-top: 30px;
  margin-left: 220px;
}
@media (max-width: 575.98px) {
  .avg_checkbox {
    margin-top: 15px;
    margin-left: 0;
  }
}
.avg_checkbox .option label {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  padding: 0 !important;
  pointer-events: all !important;
}
.avg_checkbox .option p {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: normal;
  top: 0;
  position: relative;
}
.avg_checkbox .option a {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: underline;
  color: inherit;
}

.grecaptcha-badge {
  visibility: visible;
}